<!-- 设备管理-保养管理-保养记录 -->
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>保养记录</span>
                <div class="flex align_center fn_size14 mg_left_50">
                    <div>设备编号：</div>
                    <el-input v-model="deviceID" placeholder="请输入设备编号" class="mg_left_8 width_150"></el-input>
                    <div class="mg_left_20">紧急程度：</div>
                    <el-select v-model="urgenLevelValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in urgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <div class="mg_left_20">评分等级：</div>
                    <el-select v-model="gradingArrValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in gradingArr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <div class="mg_left_30">评分等级：</div>
                    <el-select v-model="execGrade" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in execGradeArr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>    
                    <div class="mg_left_20">保养类型：</div>
                    <el-select v-model="maintainCategoryValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="mg_left_20">保养时间：</div>
                    <el-date-picker  value-format="timestamp" v-model="maintainTimeValue" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" size="medium" class="mg_left_10"
                        @click="query">查询
                    </el-button>
                    <!-- <download-excel :data   = "json_data" :fields = "json_fields" name = "用户统计列表">
                 导出
                </download-excel> -->
                <el-button type="primary" size="medium" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009bfd;">导出</el-button>
                </div>
            </div>
            
            <div class="pdl30 pdr30" style="height:85%">
                
                <!-- <div class="flex justify_between pdt_20 fn_size16">
                    <div class="flex">
                        <el-button type="primary" class="width_140" style=" background: #03CABE;border: none;">标记经验
                        </el-button>
                        <el-button type="primary" icon="el-icon-delete" class="width_140 mg_left_10"
                            style=" background: #F75753;border: none;">删除</el-button>
                    </div>
                    <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                    </el-button>
                </div> -->
                <el-table ref="multipleTable" :data="tableData" class="mg_top_20" height="100%"
                @selection-change="handleSelectionChange"
                     @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="">
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width="">
                    </el-table-column> -->
                    <el-table-column prop="deviceID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceType" label="设备类型">
                    </el-table-column> -->
                    <!-- <el-table-column prop="deviceModel" label="设备型号" width="">
                    </el-table-column> -->
                    <el-table-column prop="orderStatus_name" label="保养工单状态" width="">
                    </el-table-column>
                    <!-- <el-table-column label="保养工单状态">
                        <template slot-scope="scope">
                            <span>{{scope.row.orderStatus}}</span>
                        </template>
                    </el-table-column> -->

                    <!-- <el-table-column prop="authSort" label="审批顺序">
                    </el-table-column>
                    <el-table-column label="是否需要审批">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="deviceDepartment" label="设备所在部门" width="">
                    </el-table-column> -->
                    <!-- <el-table-column prop="deviceLocation" label="设备所在地点">
                    </el-table-column>
                    <el-table-column prop="oprPerson" label="记录人">
                    </el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execGroup" label="保养组">
                    </el-table-column> -->

                    <!-- <el-table-column prop="execPerson" label="保养人">
                    </el-table-column>
                    <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                    </el-table-column>
                    <el-table-column prop="execLevel" label="保养等级" width="">
                    </el-table-column>
                    <el-table-column prop="execType" label="保养类型">
                    </el-table-column>
                    <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）">
                    </el-table-column>
                    <el-table-column prop="urgenLevel" label="紧急程度">
                    </el-table-column>
                    <el-table-column label="是否停机">
                        <template slot-scope="scope">
                            <span>{{scope.row.machineStop}}</span>
                        </template>
                    </el-table-column> -->

                    <!-- <el-table-column prop="stopLen" label="停机时长（分钟）">
                    </el-table-column>
                    <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column label="是否逾期接单">
                        <template slot-scope="scope">
                            <span>{{scope.row.isTakeOverdue}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="endTime" label="保养结束时间" :formatter="formatDate">
                    </el-table-column> -->

                    <!-- <el-table-column label="是否逾期保养结束">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEndOverdue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                    </el-table-column> -->
                    <!-- <el-table-column prop="pauseTimeList" label="暂离时间列表" width="" show-overflow-tooltip>
                    </el-table-column> -->
                    <!-- <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="verifyRemarks" label="验证备注">
                    </el-table-column> -->
                    <!-- <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                    </el-table-column> -->

                    <!-- <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execLenM" label="保养时长（分钟）">
                    </el-table-column> -->
                    <!-- <el-table-column prop="execFee" label="保养费用（元）" width="">
                    </el-table-column>
                    <el-table-column prop="spotCondition" label="现场状况">
                    </el-table-column>
                    <el-table-column prop="execDesc" label="工作描述">
                    </el-table-column>
                    <el-table-column prop="execGrade" label="工单评分等级">
                    </el-table-column>
                    <el-table-column label="是否为经验性记录">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEmpirical}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="remarks" label="备注">
                    </el-table-column>
                    <el-table-column label="是否委外">
                        <template slot-scope="scope">
                            <span>{{scope.row.isOutEntrust}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可以进行审批" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.doAuth}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="详细">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                     <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="!scope.row.isEmpirical">
                                <!-- false,显示按钮 -->
                                <span style="border-bottom: 1px solid #009BFD;" @click="empiricalOrder(scope.row.ID)">标识经验</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        maintainRecordQuery,
        maintainOrderStatusQuery,
        maintainOrderExecGradeQuery,
        getMaintainCategory,
        getMaintainUrgenLevel,
        maintainOrderMarkEmpirical,
      
    } from "@/api/api_deviceManagement";

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                value: '',
                value1: '',
                tableData: [],
                multipleSelection: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                urgenLevelValue: '',
                urgenLevel: [],
                gradingArrValue: '',
                gradingArr: [],
                maintainCategoryValue: '',
                maintainCategory: [],
                maintainTimeValue: null,
                deviceID:'',
                execGrade:'',
                execGradeArr:[]
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            // this.maintainOrderMultiAuthQueryLimit();
            this.maintainOrderStatusQuery();
            this.maintainOrderExecGradeQuery();
            this.getMaintainCategory();
            this.getMaintainUrgenLevel();
            this.maintainRecordQuery();
        },
        methods: {
             //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '保养记录')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            async maintainRecordQuery() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    userID:this.userID 
                }
                if(this.deviceID){
                    param.deviceID = this.deviceID;
                }
                if (this.urgenLevelValue.toString() != '' && this.urgenLevelValue != null) {
                    param.urgenLevel = this.urgenLevelValue
                }
                // if (this.gradingArrValue != '' && this.gradingArrValue != null) {
                //     param.execGrade = this.gradingArrValue
                // }
                 if (this.execGrade != '' && this.execGrade != null) {
                    param.execGrade = this.execGrade
                }
                if (this.maintainCategoryValue != '' && this.maintainCategoryValue != null) {
                    param.execType = this.maintainCategoryValue
                }
                if (this.maintainTimeValue != null) {
                    param.startExecTime = this.maintainTimeValue[0];
                    param.endExecTime = this.maintainTimeValue[1];
                }
                const selectRes = await maintainRecordQuery(param);
                this.tableData = selectRes.array;
                this.pageQuantity = selectRes.pageQuantity;
            },
            handleCurrentChange(val) {
                this.maintainRecordQuery(); //查询table数据的方法
            },
            query() {
                this.currentPage = 1;
                this.maintainRecordQuery();
            },
            // 紧急程度
            async getMaintainUrgenLevel() {
                var param = {
                    userID: this.userID,
                } //用户登录时
                const selectRes = await getMaintainUrgenLevel(param);
                this.urgenLevel = selectRes.data
            },
            async rMIGradeSettingsQuery() {
                var parame = {
                    // userID:'user_ls'
                }
                const selectRes = await rMIGradeSettingsQuery(parame);
            },
            async maintainOrderStatusQuery() {
                var param = {
                    userID: this.userID,
                } //用户登录时
                const selectRes = await maintainOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            //评分等级
            async maintainOrderExecGradeQuery() {
                var param = {
                    userID: this.userID,
                } //用户登录时
                const selectRes = await maintainOrderExecGradeQuery(param);
                // this.gradingArr = selectRes.data
                this.execGradeArr=selectRes.data
            },
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },

            // 是否为经验性工单   maintainOrderMarkEmpirical
            empiricalOrder(id) {
                this.$confirm('此操作为设置经验性工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.maintainOrderMarkEmpirical(id);
                }).catch(() => {});
            },
            async maintainOrderMarkEmpirical(id) {
                var param = {
                    ID: id
                }
                const selectRes = await maintainOrderMarkEmpirical(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainRecordQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },

            gotoDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/maintainManagement/maintainRecord/recordDetails',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },
            gotoModify() {
                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/maintainPlan/modifyMaintainPlan',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .property_content {
        height: 85%;
    }
</style>